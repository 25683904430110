import Loader from "@/javascript/components/loader"
import { slideTransition } from "@/javascript/components/animations/slide"

// Provides toggling functionality
//
// Place data-toggle='target' on a link (e.g. `a data-toggle='.show-this' Click Me`)
//
// The toggler will toggle (add/remove) the class `open` on the link and the target
// It is up to the user to implement the CSS styles to do the any appropriate hiding,
// showing or any other desired transition. A common pattern is:
//
// data-toggle-within : Scopo the element to be toggled within a parent element
//
// .target {
//   .opened {
//     display: none;
//   }
//
//   .closed {
//     display: block;
//   }
//
//   &.open {
//     .opened {
//       display: block;
//     }
//
//     .closed {
//       display: none;
//     }
//   }
// }
//
// a data-toggle='.target'
//
// .target
//   .opened I'm open
//   .closed I'm closed

export class Toggler {
  constructor (elem, container, onChange, opts = {}) {
    this.elem = elem
    this.container = container
    this.always = opts.always
    this.opts = Object.assign({}, this._defaultOptions(), opts)
    this.onChange = onChange || function () {}
    this._bind()
  }

  set (visible) {
    if(visible === this.isOpen) {
      return
    }

    const animation = slideTransition(this.container, this._animationMilliseconds(), () => {
      this.isOpen = visible
      if(this.isOpen) {
        this.container.classList.add('open')
        this.elem.classList.add('open')
      } else {
        this.elem.classList.remove('open')
        this.container.classList.remove('open')
      }
    })
    animation.then(() => {
      const scrollTarget = document.querySelector(this.opts.scrollTarget)
      if(scrollTarget) {
        scrollTarget.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"})
      }
      this.elem.dispatchEvent(new Event('elementToggled', {bubbles: true, cancelable: false, element: this.elem }));
    })
    this.onChange(visible, animation, this.elem, this.container)
  }

  toggle () {
    if(this.always === 'close') {
      this.isOpen=true
    }
    if(this.always ==='open') {
      this.isOpen=false
    }
    if(!this.always) {
      this.isOpen = this.container.classList.contains('open')
    }
    this.set(!this.isOpen)
  }

  _bind () {
    this.elem.addEventListener('click', (e) => {
      e.preventDefault()
      e.stopPropagation()
      this.toggle()
    })
  }

  _defaultOptions () {
    return {
      animation: 200
    }
  }

  _animationMilliseconds () {
    let milliseconds

    if(this.opts.animation === 'false') {
      milliseconds = 0
    } else {
      milliseconds = this.opts.animation || 200
    }

    return milliseconds
  }
}

class RadioToggler extends Toggler {
  _bind () {
    document.querySelectorAll(`input[type='radio'][name='${this.elem.name}']`)
      .forEach((radio) => {
        const toggler = this
        radio.addEventListener('change', function (e) {
          toggler.set(this.value === toggler.elem.value)
        })
      })
  }
}

class CheckboxToggler extends Toggler {
  _bind () {
    this.elem.addEventListener('change', (e) => this.set(this.elem.checked))
  }
}

// Toggles content when selected value is included in `values` passed in `opts`
export class SelectizeToggler extends Toggler {
  _bind () {
    this.elem.selectize.on('change', (newValue) => this.set(this.opts.values.includes(newValue)))
  }
}

Loader.functional((content, resolve) => {
  content.querySelectorAll('[data-toggle]').forEach((elem) => {
    if(!elem.toggleEnabled && elem.dataset.toggle.length > 0) {
      elem.toggleEnabled = true
      let toggledContent = content.querySelector(elem.dataset.toggle)
      const options = {}
      options.animation = elem.dataset.toggleAnimation
      options.scrollTarget = elem.dataset.scrollTarget

      if(elem.dataset.toggleWithin) {
        toggledContent = elem.closest(elem.dataset.toggleWithin).querySelector(elem.dataset.toggle)
      }

      if(elem.nodeName === 'INPUT' && elem.type === 'radio') {
        elem.toggler = new RadioToggler(elem, toggledContent, null, options)
      } else if(elem.nodeName === 'INPUT' && elem.type === 'checkbox') {
        elem.toggler = new CheckboxToggler(elem, toggledContent, null, options)
      } else {
        elem.toggler = new Toggler(elem, toggledContent, null, options)
      }
    }
  })

  resolve()
})
